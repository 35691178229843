@import "./fonts/fonts.css";

body {
  margin: 0;
  font-family: JDSansPro,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;

  &.showing-teaser {
    overflow: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --yellow: #FFDE00;
  --yellow-hover: #E2C30A;
  --green: #367C2B;
}

p {
  font-family: JDSansProCond;
  font-size: 26px;
  margin: 1rem auto;
  text-align: center;
}

#root {


  .BrainhubCarousel__arrows {
    overflow: visible;
    z-index: 2;

    &,
    &:hover {
      background-color: transparent;
    }

    span {
      padding: 15px;
    }

    &.BrainhubCarousel__arrowLeft {
      span {
        transform: translate(-50%, -50%) rotate(-135deg) skew(15deg, 15deg);
      }
    }
    &.BrainhubCarousel__arrowRight {
      span {
        transform: translate(-50%, -50%) rotate(45deg) skew(15deg, 15deg);
      }
    }
  }

}

video { outline: none; }

.page-content {
  padding: 100px;
  padding-top: 150px;
  min-height: calc(100vh - 250px);

  @media screen and (max-width: 768px) {
    padding: 0;
    padding-top: 80px;

  }
}

p.small, p .small { font-size: 0.75em; }
.green { color: var(--green); font-weight: bold;}

.nobr { white-space: nowrap }

.iframe-container {
  // overflow: hidden;
  padding-top: 35%;
  position: relative;
  width: 50%;

  svg {
    position: absolute;
    top: 5px;
    right: -45px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    * {
      fill: white;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 90%;
    padding-top: 56.25%;

    svg {
      right: 5px;
      top: -30px;
    }
  }
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}