.OutlineButton {
  border: 1.5px solid var(--green);
  padding: 1rem 2rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: JDSansPro;
  font-weight: bold;
  font-size: 1.4rem;
  display: inline-block;
  margin-top: 1rem;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.3s ease-in;

  @media (hover: hover) {
    &:hover {
      background-color: var(--green);
      color: white;
      transition: background-color 0.3s ease-in;
    }
  }

  &.active {
    background-color: var(--green);
    color: white;
  }

  &.green {
    background-color: var(--green);
    color: white;

    @media (hover: hover) {
      &:hover {
        background-color: transparent;
        color: currentColor;
        transition: background-color 0.3s ease-in;
      }
    }
  }

  &.yellow {
    border: 1px solid var(--yellow);
    background-color: var(--yellow);
    color: black;

    &:hover {
      border: 1px solid var(--yellow-hover);
      background-color: var(--yellow-hover);
      // transition: background-color 0.3s ease-in;
    }
  }

  &.yellow-outline {
    border: 1px solid var(--yellow);
    background-color: rgba($color: #000000, $alpha: 0.3);
    color: white;

    &:hover {
      border: 1px solid var(--yellow-hover);
      background-color: var(--yellow-hover);
      color: black;
      // transition: background-color 0.3s ease-in;
    }
  }

  &.solid {
    &.green {
      background-color: var(--green);
    }
    &.yellow {
      background-color: var(--yellow);
    }
  }

  &.header {
    margin: 0;
    padding: 1.4rem 1.2rem 1.85rem 1.5rem;
    font-family: JDSansProCond;

    &:hover {
      color: white;
    }

    @media screen and (max-width: 1024px) {
      span {
        display: none;
      }
    }
    @media screen and (max-width: 1333px) {
      padding: 1.6rem 1.2rem 1.8rem 1.5rem;
    }
  }
}
