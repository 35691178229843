.footer {
  background-color: #eee;
  padding: 1rem 4rem;
  display: flex;
  position: relative;
  z-index: 1;
  // margin-top: 200px;
  font-family: JDSansPro;
  color: #444;
  align-items: center;
  justify-content: center;

  img {
    width: 32px;
  }

  svg {
    height: 22px;
  }

  > div {
    flex: 1;

    &.left {
      padding: 0.5rem;
    }

    &.right {
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .social {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      a {
        margin: 0 1.2rem;
      }

      span {
        position: relative;
        top: -10px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 0 0.5rem;

    .left {
      flex: 2;
    }

    .right {
      flex: 2;

      span {
        display: none;
      }
    }

    img {
      width: 30px;
      margin: 0.5rem 0.25rem;
    }
  }

  @media screen and (max-width: 800px) {
    font-size: 12px;
    padding: 0.5rem 0.25rem;
    flex-direction: column;

    .left {
      flex: 2;
      order: 2;
    }

    .right {
      flex-direction: column;
      flex: 2;
      order: 1;
    }

    .social {
      margin-top: 0.5rem;
    }

    img {
      width: 25px;
      margin: 0.5rem 0.25rem;
    }
  }
}
