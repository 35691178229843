body {
  overflow-x: hidden;
}

.LivePage2023 {
  margin-top: 40px;

  .page-intro {
    font-weight: 700;
    font-size: 28px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-family: JDSansProCond;

    @media screen and (max-width: 768px) {
      font-size: 24px;
      letter-spacing: 1px;
    }
  }

  .DateTimeCallout {
    background-image: url(./img/2022/hero-redirect.jpg);
    background-size: cover;
    background-position: center;
    height: 34.5vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: auto;
    max-width: 1200px;
    margin-top: 60px;
    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }

    h2 {
      color: white;
      text-transform: uppercase;
      font-family: JDSansProCond;
      font-size: 72px;
      font-weight: 800;
      margin-bottom: 2rem;
    }

    &__title {
      @media screen and (max-width: 768px) {
        font-size: 48px;
      }
    }

    &__content {
      text-align: center;

      @media screen and (max-width: 768px) {
        .OutlineButton {
          background-color: var(--yellow);
          color: black;
        }
      }
    }

    &__copy {
      text-align: center;
      margin: 0 auto;
      font-family: JDSansProCond;
      font-weight: 300;
      font-size: 2rem;
      margin-top: 2rem;
    }

    @media screen and (max-width: 768px) {
      height: auto;
      max-height: none;
      // background-image: url("./img/2023/sprayer-mobile.png");
      padding-top: 60vw;
      width: 100%;
      background-position: top center;
      background-size: 100%;
      background-repeat: no-repeat;

      &__content,
      &__copy {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
      }

      h2 {
        color: var(--green);
        text-align: center;
        line-height: 1;
        margin: 0;
      }
    }

    @media screen and (max-width: 768px) {
      h2 {
        font-size: 48px;
      }
    }

    @media screen and (max-width: 500px) {
      h2 {
        font-size: 32px;
      }
    }

    @media screen and (max-width: 400px) {
      h2 {
        font-size: 26px;
      }
    }

  }
}

.FancyHr {
  height: 2px;
  max-width: 455px;
  margin: 3rem auto;
  background-color: var(--green);
}

.PadPanel {
  @media screen and (max-width: 768px) {
    padding: 0 2rem;
  }
}

.PipeJoined {
  .PipeJoined__pipe {
    &::after {
      content: "|";
      margin: 0 1rem;
    }

    @media screen and (max-width: 768px) {
      display: block;


      &::after {
        content: "";
        margin: 0;
      }
    }
  }
}

.TeaserHero {
  height: 30vw;
  background: url("./img/2024/teaser-hero.jpg") no-repeat fixed 0 0 / 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  position: relative;

  @media screen and (min-aspect-ratio: 16/9) {
    height: 50vh;
    // background-image: url("./img/2023/hero2.jpg");
    background-size: cover;
    background-position: left bottom;
  }

  .RealLogo {
    width: auto;
    min-width: 300px;
    // max-width: 500px;
    height: 70%;
    // max-height: 100px;
    margin: auto auto;

  }


  .TeaserHero__content {
    max-height: 400px;
    padding: 4rem 12rem;
    width: auto;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    color: white;
    position: relative;
    z-index: 0;
    display: flex;
    border: 1px solid;
    // border-image-slice: 1;
    border-width: 2px;
    // border-image-source: linear-gradient(to left, #25b0f1, #62c584);

    &:before {
      // background: url("./img/2023/hero.jpg") no-repeat 0 0 / 100%;
      content: "";
      backdrop-filter: blur(5px) brightness(0.6);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }

  }
  @media screen and (max-width: 768px) {
    .RealLogo {
      max-width: 50%;
      min-width: 200px;
    }

    .TeaserHero__content {
      padding: 2rem;
      max-width: 80%;
    }
  }
  @media screen and (max-width: 408px) {
  .TeaserHero__content {
    max-width: 220px;
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

  }

  @media screen and (max-width: 768px) {
    height: 33vh;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-attachment: scroll;
  }
}