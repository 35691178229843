.Accolades {
  padding: 4rem 0;
  font-size: 1.5rem;
  background-color: var(--green);
  color: white;
  padding-bottom: 100px;

  h2 {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-bottom: 5%;
    color: white;
  }

  h4 {
    font-size: 25px;
    margin: 0;
  }

  h5 {
    font-size: 20px;
    margin: 0;
  }

  h6 {
    font-size: 20px;
    margin: 0;
    font-weight: normal;
  }

  .flexHorizontal {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    max-width: 700px;
    margin: 0 auto;
  }

  .image-side {
    text-align: center;
    img {
      max-height: 200px;
    }
  }

  .text-side {
    flex-grow: 1;
    padding: 0 5%;

    hr {
      border: 1px solid white;
      margin: 5% 0;
      width: 80%;
    }
  }

  @media screen and (max-width: 768px) {
    text-align: center;

    .image-side {
      margin-bottom: 10px;
    }

    .text-side hr {
      margin: 5% auto;
    }
  }
}
